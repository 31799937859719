
/* --------- */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #fcfcfc;
    --tw-bg-opacity: 1;
    background-image: url(./Assets/pre3.gif);
    background-repeat: no-repeat;
    background-position: center;
   
  }

  .gallery .gallery-item {
    overflow: hidden;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
}

.gallery .gallery-item img {
    transition: all ease-in-out 0.8s;
}

.gallery .gallery-item:hover img {
    transform: scale(1.1);
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2c4964;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #1977cc;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

  
  #preloader-none {
    opacity: 0;
  }
  
  #no-scroll {
    overflow: hidden;
    height: 100vh;
  }
  
  /* --------- */
  /*Scrollbar   */
  /* --------- */
  
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #201a2b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(87, 78, 92, 0.959);
    border-radius: 12px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(87, 78, 92, 0.959);
    border-radius: 12px;
  }

  @media (max-width: 767px) {
    #preloader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      /* animation: zoom-in 2.5s infinite; */
      height: 100%;
      z-index: 999999;
      background-color: #fcfcfc;
      --tw-bg-opacity: 1;
      background-image: url(./Assets/pre2.gif);
      background-repeat: no-repeat;
      background-position: center;
     
    }
    /* @keyframes zoom-in {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(2.5, 2.5);
      }
    } */
    
    #preloader-none {
      opacity: 0;
    }
    
    #no-scroll {
      overflow: hidden;
      height: 100vh;
    }

  }  